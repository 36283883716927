<template>
  <v-snackbar v-model="show" :color="color" :timeout="10000" :top="true">
    {{ message }}
    <v-btn dark flat @click="show = false">Close</v-btn>
  </v-snackbar>
</template>

<script>
import EventBus from '@/eventBus'

export default {
  data: () => ({
    show: false,
    color: '',
    message: ''
  }),
  mounted() {
    EventBus.$on('toast', payload => {
      this.color = payload.color || 'error'
      this.message = payload.message
      this.show = true
    })
  }
}
</script>
