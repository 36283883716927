<template>
<div>
<h1>No Permission</h1>
</div>
</template>

<script>
  export default {
  }
</script>
