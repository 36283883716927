<template>
<iframe src='https://maps.darksky.net/@temperature,35.236,-97.628,10?domain="+encodeURIComponent(window.location.href)+"&auth=1557961795_cb9cb11f2d29ea518262ab08d076f63e&embed=true&amp;timeControl=true&amp;fieldControl=true&amp;defaultField=temperature&amp;defaultUnits=_f' frameborder="0"></iframe>
<!-- <div></div> -->
<!-- <iframe src="https://embed.windy.com/embed2.html?lat=35.2355507604&lon=-97.6276537804&zoom=11&level=surface&overlay=wind&menu=&message=&marker=&calendar=12&pressure=&type=map&location=coordinates&detail=true&detailLat=35.197&detailLon=-97.294&metricWind=default&metricTemp=°F&radarRange=-1" frameborder="0"></iframe> -->
<!-- <script src='https://darksky.net/map-embed/@temperature,35.236,-97.628,10.js?embed=true&timeControl=true&fieldControl=true&defaultField=temperature&defaultUnits=_f'></script> -->
</template>

<script>
  export default {
  }
</script>

<style scoped>
iframe {
  height: 100%;
  width: 100%;
}
</style>

