import Vue from 'vue'
import Router from 'vue-router'
import Fiber from './views/Fiber.vue'
import Table from './views/Table.vue'
import MapComponent from './views/Map.vue'
import Forecast from './views/Forecast.vue'
import NoPermission from './views/NoPermission.vue'
// import Logout from './views/Logout.vue'

Vue.use(Router)

let router = new Router({
  // mode: process.env.CORDOVA_PLATFORM ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/fiber'
    },
    {
      path: '/fiber',
      name: 'fiber',
      component: Fiber
    },
    {
      path: '/table',
      name: 'table',
      component: Table
    },
    {
      path: '/map/:mapLocation?',
      name: 'map',
      component: MapComponent
    },
    {
      path: '/forecast',
      name: 'forecast',
      component: Forecast
    },
    {
      path: '/nopermission',
      name: 'nopermission',
      component: NoPermission
    },
    // {
    //   path: '/logout',
    //   name: 'logout',
    //   component: Logout
    // }
  ]
})

export default router
