import http from '@/http'
import handleError from '@/handleError'

export default {
  namespaced: true,
  state: {
    loading: false,
    data: []
  },

  mutations: {
    set_loading(state, loading) {
      state.loading = loading
    },
    save_data(state, data) {
      state.data = data
    }
  },

  actions: {
    fetchTableData({ commit }) {
      commit('set_loading', true)
      http
        .get('/accounts')
        .then(response => {
          // response.data.accounts.forEach(i => {
          //   i.ZONE
          // }) 
          commit('save_data', response.data.accounts)
        })
        .catch(handleError)
        .finally(() => commit('set_loading', false))
    }
  }
}
