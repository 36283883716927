<template>
  <div id="map" class="map"></div>
</template>

<script>
import handleError from '@/handleError'
import EventBus from '@/eventBus'
import { modules } from '@/arcgis'

export default {
  data: () => ({
    layerReady: null,
    searchWidget: null
  }),
  methods: {
    goTo(mapLocation) {
      console.log('goTo()')
      this.searchWidget
        .suggest(mapLocation)
        .then(suggestResponse => {
          const results = suggestResponse.results[2].results
          if (results.length > 0) {
            this.searchWidget.search(results[0])
          } else {
            EventBus.$emit('toast', {
              message: `Unable to map to ${mapLocation}`,
              color: 'warning'
            })
          }
        })
        .catch(handleError)
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const mapLocation = vm.$route.params.mapLocation
      if (mapLocation && vm.layerReady) {
        vm.searchWidget.clear()
        vm.goTo(mapLocation)
      }
    })
  },
  mounted() {
    const gisUrl = 'https://api.okcoop.org/azxsw38010'

    let vm = this

    modules
      .then(
        ([
          Map,
          MapView,
          MapImageLayer,
          FeatureLayer,
          Extent,
          SpatialReference,
          Home,
          Viewpoint,
          Search,
          Locator,
          Locate,
          Expand,
          BasemapGallery,
          LayerList,
          Legend,
          IdentifyTask,
          IdentifyParameters
        ]) => {
          const mapImageLayer = new MapImageLayer({
            url: `${gisUrl}/Wall_Map`,
            title: 'OEC Web Map',
            refreshInterval: 1,
            fullExtent: new Extent({
              // autocasts as new Extent()
              xmin: -98.23138252411876,
              ymin: 34.909163726337574,
              xmax: -97.02392503671017,
              ymax: 35.561937794407555,
              spatialReference: new SpatialReference({ wkid: 4326 })
            })
          })

          const boundaries = new MapImageLayer({
            url: `${gisUrl}/Boundaries`,
            title: 'Boundaries'
          })

          const openFiberZones = new FeatureLayer({
            url:
              'https://services8.arcgis.com/iZpPZ9CLUQLpRZ4B/ArcGIS/rest/services/Open_Fiber_Zones/FeatureServer',
            layerId: 0,
            refreshInterval: 20,
            visible : false
          })

          var innerCutOuts = new FeatureLayer({
            url:
              'https://services8.arcgis.com/iZpPZ9CLUQLpRZ4B/arcgis/rest/services/Inner_Zone_Cutouts/FeatureServer',
            refreshInterval: 20,
            visible : false
          })

          var zonesByFeeder = new FeatureLayer({
            url:
              'https://services8.arcgis.com/iZpPZ9CLUQLpRZ4B/ArcGIS/rest/services/Zone_By_Feeder/FeatureServer',
            refreshInterval: 20,
            visible : false
          })

          const wLayer = new MapImageLayer({
            // url: "https://nowcoast.noaa.gov/arcgis/rest/services/nowcoast/radar_meteo_imagery_nexrad_time/MapServer",
            url:
              'https://idpgis.ncep.noaa.gov/arcgis/rest/services/NWS_Observations/radar_base_reflectivity/MapServer',
            title: 'National Radar Imagery',
            opacity: 0.2,
            refreshInterval: 0.1
          })

          const map = new Map({
            basemap: '',
            // ground: "world-elevation",
            layers: [openFiberZones,zonesByFeeder,innerCutOuts, wLayer, mapImageLayer,boundaries]
          })

          const view = new MapView({
            container: 'map',
            map: map
          })

          var legend = new Legend({
            view: view,
            style: 'classic' // other styles include 'card'
          })
          const lExpand = new Expand({
            view: view,
            autoCollapse: true,
            content: legend
          })
          view.ui.add(lExpand, 'bottom-right')

          const search = new Search({
            view: view,
            //locationEnabled: true,
            suggestionsEnabled: true,
            sources: [
              {
                locator: new Locator({
                  url:
                    '//geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer'
                }),
                singleLineFieldName: 'SingleLine',
                name: 'Address',
                localSearchOptions: {
                  minScale: 300000,
                  distance: 50000
                },
                placeholder: 'Search Geocoder',
                maxResults: 3,
                maxSuggestions: 6,
                suggestionsEnabled: true,
                minSuggestCharacters: 0
              },
              {
                featureLayer: {
                  //url: "http://38010gis:6080/arcgis/rest/services/GIS_Services/CIS/MapServer/31",CIS/48
                  url: `${gisUrl}/Wall_Map/33`, // "https://api.okcoop.org/azxsw38010/CIS/46", //Fiber/FeatureServer/1",
                  autoSelect: true,
                  suggestionsEnabled: true,
                  maxResults: 6,
                  maxSuggestions: 6,
                  popupTemplate: {
                    title:
                      '<h1>test</h1>{gs_name}</br>{gs_service_map_location}',
                    overwriteActions: true
                  }
                },
                searchFields: [
                  'gs_service_address',
                  'gs_service_map_location',
                  'gs_account_number',
                  'gs_name',
                  'gs_meter_number',
                  'gs_phone_nbr',
                  'gs_phone_nbr_2',
                  'gs_phone_nbr_3'
                ],
                displayField: 'gs_name',
                exactMatch: false,
                outFields: ['*'],
                name: 'OEC Search',
                zoomScale: 3000,
                placeholder: 'example: Tom Bramlet'
                // suggestionTemplate: "{gs_name}",
              }, //4056597338
              {
                featureLayer: {
                  //url: "http://38010gis:6080/arcgis/rest/services/GIS_Services/CIS/MapServer/29",CIS/46
                  url: `${gisUrl}/Wall_Map/31`, //Fiber/FeatureServer/0",
                  popupTemplate: {
                    overwriteActions: true
                  }
                },
                searchFields: ['gs_pole_number'],
                displayField: 'gs_pole_number',
                exactMatch: false,
                outFields: ['*'],
                name: 'Pole Number',
                zoomScale: 3000,
                placeholder: 'example: 45W1-46',
                suggestionTemplate: '{gs_pole_number}',
                suggestionsEnabled: true,
                maxSuggestions: 6
              }
            ]
          })
          this.searchWidget = search

          search.on('select-result', event => {
            let pos = event.result.feature.geometry
            let f = event.result.feature.attributes
            search.sources.items[1].featureLayer.popupTemplate.title = `<div style="float: right"><button onclick="window.navigate(${
              pos.latitude
            }, ${
              pos.longitude
            })"><img style="width: 100px" src="img/car.png" alt="car" /></button></div>{gs_name}</br>{gs_service_map_location}</br>{gs_account_number}</br>{gs_meter_number}</br>{gs_service_address}</br>${f.gs_phone_nbr ||
              f.gs_phone_nbr_2 ||
              f.gs_phone_nbr_3 ||
              'No phone number'}`
          })

          const searchExpand = new Expand({
            content: search,
            autoCollapse: true
          })
          view.ui.add(searchExpand, 'top-right')

          // Creating a BasemapGallery widget instance and set
          // its container to a div element
          var basemapGallery = new BasemapGallery({
            view: view
            //container: document.createElement("div")
          })
          // Create an Expand instance and set the content
          // property to the DOM node of the basemap gallery widget
          const bgExpand = new Expand({
            view: view,
            autoCollapse: true,
            content: basemapGallery
          })
          // Add the expand instance to the ui
          view.ui.add(bgExpand, 'top-right')

          var identifyTask, params

          view.when(() => {
            console.log('viewMap is ready')
            view.goTo({ target: mapImageLayer.fullExtent })

            var homeButton = new Home({
              id: 'home-button',
              view: view,
              container: document.createElement('div'),
              viewpoint: new Viewpoint({
                targetGeometry: mapImageLayer.fullExtent
              })
            })
            view.ui.add(homeButton, 'top-left')

            var locateBtn = new Locate({
              view: view
            })
            // Add the locate widget to the top left corner of the view
            view.ui.add(locateBtn, {
              position: 'top-left'
            })

            //layer list object
            var layerList = new LayerList({
              view: view
            })
            const lListExpand = new Expand({
              view: view,
              autoCollapse: true,
              content: layerList
            })
            // Add widget to the top right corner of the view
            view.ui.add(lListExpand, 'top-left')

            //event when clicking the view
            view.on('click', function(event) {
              //collapse widgets
              lListExpand.collapse()
              bgExpand.collapse()
              lExpand.collapse()
              searchExpand.collapse()

              // Create identify task for the specified map service
              identifyTask = new IdentifyTask(`${gisUrl}/Wall_Map/`)

              // Set the parameters for the Identify
              params = new IdentifyParameters({ returnGeometry: true })
              params.tolerance = 20
              params.layerIds = [33, 32, 31]
              params.layerOption = 'all'
              params.width = view.width
              params.height = view.height

              executeIdentifyTask(event)
            })
          })

          mapImageLayer.when(() => {
            console.log('mapImageLayer is ready')
            this.layerReady = true
            const mapLocation = vm.$route.params.mapLocation
            if (mapLocation) {
              vm.goTo(mapLocation)
            }
          })

          function executeIdentifyTask(event) {
            // Set the geometry to the location of the view click
            params.geometry = event.mapPoint
            params.spatialReference = event.mapPoint.SpatialReference
            params.mapExtent = view.extent
            document.getElementById('map').style.cursor = 'wait'

            // This function returns a promise that resolves to an array of features
            // A custom popupTemplate is set for each feature based on the layer it
            // originates from
            identifyTask
              .execute(params)
              .then(function(response) {
                var results = response.results

                return results.map(function(result) {
                  var feature = result.feature
                  var layerName = result.layerName
                  var attr = feature.attributes

                  var lat = feature.geometry.latitude
                  var long = feature.geometry.longitude

                  attr.layerName = layerName
                  // if (layerName === "gs_service_point") {
                  var content = `<div style="float: right"><button onclick="window.navigate(${lat}, ${long})"><img style="width: 100px" src="img/car.png" alt="car" /></button></div>`
                  for (var p in attr) {
                    if (attr.hasOwnProperty(p)) {
                      var v = attr[p]
                      if (v !== 'Null') {
                        content += `<b>${p}</b>: ${v}<br>`
                      }
                    }
                  }

                  feature.popupTemplate = {
                    // autocasts as new PopupTemplate()
                    title: attr.layerName,
                    content: content
                  }
                  // } else if (layerName === "State Soil Geographic") {
                  //   feature.popupTemplate = {
                  //     // autocasts as new PopupTemplate()
                  //     title: "{Map Unit Name}",
                  //     content:
                  //       "<b>Dominant order:</b> {Dominant Order} ({Dominant %}%)" +
                  //       "<br><b>Dominant sub-order:</b> {Dominant Sub-Order} ({Dominant Sub-Order %}%)"
                  //   };
                  // } else if (layerName === "Global Soil Regions") {
                  //   feature.popupTemplate = {
                  //     // autocasts as new PopupTemplate()
                  //     title: layerName,
                  //     content:
                  //       "<b>Dominant order:</b> {Dominant Order}" +
                  //       "<br><b>Dominant sub-order:</b> {Dominant Sub-Order}"
                  //   };
                  // }
                  return feature
                })
              })
              .then(showPopup) // Send the array of features to showPopup()

            // Shows the results of the Identify in a popup once the promise is resolved
            function showPopup(response) {
              if (response.length > 0) {
                view.popup.open({
                  features: response,
                  location: event.mapPoint
                })
              }
              document.getElementById('map').style.cursor = 'auto'
            }
          }
        }
      )
      .catch(handleError)
  }
}
</script>

<style scoped>
.map {
  height: 100%;
}
</style>
