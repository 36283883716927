<template>
  <v-app>
    <!-- <v-navigation-drawer
      v-if="!isCordova && !$vuetify.breakpoint.mdAndUp"
      fixed
      v-model="drawer"
      clipped
      app
    >
      <v-list dense>
        <v-list-tile to="/fiber">
          <v-list-tile-action>
            <v-icon>show_chart</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>FIBER</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-list-tile to="/table">
          <v-list-tile-action>
            <v-icon>search</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>SEARCH</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-list-tile to="/map">
          <v-list-tile-action>
            <v-icon>place</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Map</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-list-tile to="/forecast">
          <v-list-tile-action>
            <v-icon>wb_sunny</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Forecast</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-list-tile @click="logout()">
          <v-list-tile-action>
            <v-icon>power_settings_new</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Logout</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>



      </v-list>
    </v-navigation-drawer> -->
    <v-toolbar v-if="!isCordova" fixed :app="$vuetify.breakpoint.mdAndUp" clipped-left class="hidden-sm-and-down">
      <!-- <v-toolbar-side-icon @click="drawer = !drawer" class="hidden-md-and-up"></v-toolbar-side-icon> -->
      <v-btn flat @click="reloadPage()">
        <v-img :src="require('@/assets/OECFiberLogo.png')"></v-img>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn to="/fiber" color="teal" flat>
          <v-icon>show_chart</v-icon>
          <span style="font-size: 10px">Fiber</span>
        </v-btn>

        <v-btn to="/table" color="teal" flat>
          <v-icon>search</v-icon>
          <span style="font-size: 10px">Search</span>
        </v-btn>

        <v-btn to="/map" color="teal" flat>
          <v-icon>place</v-icon>
          <span style="font-size: 10px">Map</span>
        </v-btn>

        <v-btn to="/forecast" color="teal" flat>
          <v-icon>wb_sunny</v-icon>
          <span style="font-size: 10px">Forecast</span>
        </v-btn>

        <!-- <v-btn to="/logout" color="teal" flat> -->
        <v-btn @click="logout()" flat>
          <v-icon>power_settings_new</v-icon>
          <span style="font-size: 10px">Logout</span>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-content>
      <transition name="fade" mode="out-in">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </transition>
    </v-content>

    <v-bottom-nav :value="true" fixed :app="!$vuetify.breakpoint.mdAndUp" class="hidden-md-and-up">
      <v-btn to="/fiber" color="teal" flat style="min-width:40px;" class="px-0">
        <span style="font-size: 10px">Fiber</span>
        <v-icon>show_chart</v-icon>
      </v-btn>

      <v-btn to="/table" color="teal" flat style="min-width:40px;" class="px-0">
        <span style="font-size: 10px">Search</span>
        <v-icon>search</v-icon>
      </v-btn>

      <v-btn to="/map" color="teal" flat style="min-width:40px;" class="px-0">
        <span style="font-size: 10px">Map</span>
        <v-icon>place</v-icon>
      </v-btn>

      <v-btn to="/forecast" color="teal" flat style="min-width:40px;" class="px-0">
        <span style="font-size: 10px">Forecast</span>
        <v-icon>wb_sunny</v-icon>
      </v-btn>

      <v-btn flat @click="reloadPage()" style="min-width:40px;" class="px-0">
        <span style="font-size: 10px">Refresh</span>
        <v-icon>refresh</v-icon>
      </v-btn>

      <!-- <v-btn to="/logout" color="teal" flat> -->
      <v-btn @click="logout()" flat style="min-width:40px;" class="px-0">
        <span style="font-size: 10px">Logout</span>
        <v-icon>power_settings_new</v-icon>
      </v-btn>
    </v-bottom-nav>

    <toast-message/>

    <v-dialog v-model="showLoginDialog" persistent max-width="500">
      <v-card>
        <v-toolbar>
          <v-toolbar-title>Login</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="username"
              prepend-icon="person"
              name="login"
              label="Login"
              type="text"
              @keypress.enter="login()"
            ></v-text-field>
            <v-text-field
              v-model="password"
              id="password"
              prepend-icon="lock"
              name="password"
              label="Password"
              type="password"
              @keypress.enter="login()"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="login()" :loading="loggingIn">Login</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <update-dialog v-if='isCordova' />
  </v-app>
</template>

<script>
import EventBus from '@/eventBus'
import ToastMessage from '@/components/ToastMessage'
import UpdateDialog from '@/components/UpdateDialog'
import handleError from '@/handleError'
import http from '@/http'

export default {
  name: 'App',
  components: {
    'update-dialog': UpdateDialog,
    'toast-message': ToastMessage
  },
  data: () => ({
    username: '',
    password: '',
    loggingIn: false,
    isCordova: false,
    drawer: false
  }),
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    showLoginDialog() {
      return !this.isLoggedIn
    }
  },
  methods: {
    reloadPage() {
      location.reload(true)
    },
    refreshData() {
      this.$store.dispatch('fetchVersion')

      this.$store.state.fiber.loadingChartData = true
      http
        .get('/chart')
        .then(response => {
          this.$store.state.fiber.chartData = response.data.chart
          EventBus.$emit('renderChart')
        })
        .catch(handleError)
        .finally(() => this.$store.state.fiber.loadingChartData = false)

      this.$store.state.fiber.loadingChartTotal = true
      http
        .get('/chartTotal')
        .then(response => this.$store.state.fiber.chartTotal = response.data.chartTotal[0])
        .catch(handleError)
        .finally(() => this.$store.state.fiber.loadingChartTotal = false)

      this.$store.dispatch('table/fetchTableData')
    },
    login() {
      let username = this.username
      let password = this.password
      this.loggingIn = true
      this.$store
        .dispatch('login', { username, password })
        .then(() => {
          this.refreshData()
          if (this.$route.name === 'nopermission') {
            this.$router.push('/')
          }
        })
        .catch(err => console.log(err))
        .finally(() => (this.loggingIn = false))
    },
    logout() {
      this.$store.dispatch('logout')
    }
  },
  errorCaptured(err) {
    handleError(err)
  },
  created() {
    this.isCordova = !!window.cordova

    // TODO: Fetch all data.
    if (this.isLoggedIn) {
      this.refreshData()
    }
  }
}
</script>

<style>
@import url('https://js.arcgis.com/4.8/esri/themes/light/main.css');

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.v-bottom-nav {
  background-color: #ffffffe6 !important;
}
</style>
