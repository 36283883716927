import Axios from 'axios'
import store from '@/store'
import EventBus from '@/eventBus'

const http = Axios.create({
  baseURL: 'https://api.okcoop.org/test/fiber'
  // baseURL: 'http://localhost:3000'
})

http.interceptors.response.use(undefined, err => {
  return new Promise((resolve, reject) => {
    if (err.response.status === 403) {
      EventBus.$emit('toast', { message: 'Permission Denied', color: 'error' })
      store.dispatch('logout')
      reject(new Error('Permission Denied'))
    } else if (
      err.response &&
      (err.response.status === 401) &&
      err.config &&
      !err.config.__isRetryRequest
    ) {
      store.dispatch('logout')
      reject(new Error('Token expired'))
    }
    reject(err)
  })
})

export default http
