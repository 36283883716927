import { loadScript, loadModules } from 'esri-loader'

loadScript({ url: 'https://js.arcgis.com/4.8/' })

let modules = loadModules([
  'esri/Map',
  'esri/views/MapView',
  'esri/layers/MapImageLayer',
  "esri/layers/FeatureLayer",
  'esri/geometry/Extent',
  'esri/geometry/SpatialReference',
  'esri/widgets/Home',
  'esri/Viewpoint',
  'esri/widgets/Search',
  'esri/tasks/Locator',
  'esri/widgets/Locate',
  'esri/widgets/Expand',
  'esri/widgets/BasemapGallery',
  'esri/widgets/LayerList',
  'esri/widgets/Legend',
  'esri/tasks/IdentifyTask',
  'esri/tasks/support/IdentifyParameters'
])

export { modules }