import Vue from 'vue'
import Vuex from 'vuex'
import http from '@/http'

import FiberModule from '@/store/fiber'
import TableModule from '@/store/table'
import handleError from '@/handleError'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user: {},
    version: '',
    message: '',
    isClosed: true
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, token, user) {
      state.status = 'success'
      state.token = token
      state.user = user
    },
    auth_error(state) {
      state.status = 'error'
    },
    logout(state) {
      console.log('logout')
      state.status = ''
      state.token = ''
    },
    set_version(state, data) {
      state.version = data.version
      state.message = data.message
      state.isClosed = false
    },
    close_update_dialog(state) {
      state.isClosed = true
    }
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        http({ url: '/login', data: user, method: 'POST' })
          .then(resp => {
            const token = resp.data.token
            //const user = resp.data.user
            localStorage.setItem('token', token)
            http.defaults.headers.common['Authorization'] = token
            commit('auth_success', token, { name: 'Test User' })
            resolve(resp)
          })
          .catch(err => {
            commit('auth_error')
            localStorage.removeItem('token')
            handleError(err)
            reject(err)
          })
      })
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit('logout')
        localStorage.removeItem('token')
        delete http.defaults.headers.common['Authorization']
        resolve()
      })
    },
    fetchVersion({ commit }) {
      http
        .get('/version')
        .then(response => {
          commit('set_version', response.data)
        })
        .catch(handleError)
    },
    closeUpdateDialog({ commit }) {
      commit('close_update_dialog')
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status
  },
  modules: {
    fiber: FiberModule,
    table: TableModule
  }
})
