<template>
  <v-dialog v-model="showDialog" persistent max-width="500">
    <v-card>
      <v-card-title class="headline">New Version is here!</v-card-title>
      <v-card-text>
        <div class="divider"/> {{ $store.state.message }}
      </v-card-text>
      <v-card-actions>
        <v-btn flat @click="closeDialog()">UPDATE LATER</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="update()" color="info">UPDATE NOW</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  methods: {
    update() {
      window.location.href =
        // 'https://build.phonegap.com/apps/3563258/install/q4vscYUs4ycyfpXBNx8T'
        'https://build.phonegap.com/apps/3550692/install/uXL52cxksuJB4rFfnfAu'
    },
    closeDialog() {
      this.$store.dispatch('closeUpdateDialog')
    }
  },
  computed: {
    showDialog() {
      if (this.currentVersion === null) return false

      return this.currentVersion != '0.02' && !this.isClosed && this.isLoggedIn
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    currentVersion() {
      return this.$store.state.version
    },
    isClosed() {
      return this.$store.state.isClosed
    }
  }
}
</script>

<style scoped>
.divider {
  height: 5px;
  background-color: orange;
  margin: -20px 0 20px 0;
}
</style>
