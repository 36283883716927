<template>
  <v-container align-center justify-space-around>
    <v-card class="elevation-3">
      <v-toolbar>
        <v-layout>
          <v-flex xs4>
            <v-select
              label="Status"
              :items="statusOptions"
              v-model="statusSelection"
              :disabled="loading"
            ></v-select>
          </v-flex>
          <v-flex xs4 class="hidden-xs-only">
            <v-select
              label="Task"
              :items="taskOptions"
              v-model="taskSelection"
              :disabled="loading"
            ></v-select>
          </v-flex>
          <v-flex xs4 class="hidden-sm-and-down">
            <v-select
              label="Stage"
              :items="stageOptions"
              v-model="stageSelection"
              :disabled="loading"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-text-field
          style="margin-top:-20px"
          @input="debounceSearch"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-menu offset-y :nudge-left="170" :close-on-content-click="false">
          <v-btn icon slot="activator">
            <v-icon>more_vert</v-icon>
          </v-btn>
          <v-list>
            <v-list-tile
              v-for="item in headers"
              :key="item.value"
              @click="changeSort(item.value)"
            >
              <v-list-tile-title>
                {{ item.text }}
                <v-icon v-if="pagination.sortBy === item.value">{{
                  pagination.descending ? 'arrow_downward' : 'arrow_upward'
                }}</v-icon>
              </v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
        <v-btn @click="exportCsv()" class="hidden-sm-and-down" icon>
          <v-icon>library_books</v-icon>
        </v-btn>
      </v-toolbar>

      <!-- <v-layout v-resize="onResize" column> -->
      <v-layout column>
        <v-card class="ma-3 elevation-3">
          <!-- <v-data-table
        :items="rawData"
        :headers="headers"
        :search="search"
        :pagination.sync="pagination"
        :hide-headers="isMobile"
        :class="{mobile: isMobile}"
          >-->
          <v-data-table
            :items="stageFilteredRawData"
            :headers="headers"
            :search="search"
            :loading="loading"
            :pagination.sync="pagination"
          >
            <template slot="items" slot-scope="props">
              <!-- <tr v-if="!isMobile"> -->
              <tr>
                <td>
                  <a @click="goTo(props.item.BI_SRV_MAP_LOC)">{{
                    props.item.BI_SRV_MAP_LOC
                  }}</a>
                </td>
                <td>{{ props.item.Name }}</td>
                <td>{{ props.item.BI_ADDR1 }}</td>
                <td>{{ props.item.BI_CITY }}</td>
                <td>{{ props.item.ZONE }}</td>
                <td>{{ props.item.OPEN_DT }}</td>
                <td>{{ props.item.TASK }}</td>
                <td>{{ props.item.WORKGRP }}</td>
                <td>{{ props.item.ASSIGNED }}</td>
                <td style="width: 50px">
                  <a @click="getComments(props.item.SO)"
                    ><v-icon>comment</v-icon></a
                  >
                  <a @click="getCT(props.item.CUST_NUM)"
                    ><v-icon>headset_mic</v-icon></a
                  >
                </td>
                <td>{{ props.item.PHONE_NUM }}</td>
                <td>{{ props.item.EMAIL }}</td>
              </tr>
              <!-- <tr v-else>
            <td>
              <ul class="flex-content">
                <li class="flex-item" data-label="Acct">{{ props.item.ACCT }}</li>
                <li class="flex-item" data-label="Address">{{ props.item.BI_ADDR1 }}</li>
                <li class="flex-item" data-label="City">{{ props.item.BI_CITY }}</li>
                <li class="flex-item" data-label="County">{{ props.item.BI_CNTY_CD }}</li>
                <li class="flex-item" data-label="Status">{{ props.item.STATUS }}</li>
              </ul>
            </td>
              </tr>-->
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
              >Your search for "{{ search }}" found no results.</v-alert
            >
          </v-data-table>
        </v-card>
      </v-layout>
    </v-card>

    <v-dialog v-model="commentsDialog">
      <v-card class="pa-3">
        <v-card class="pa-3">
          <v-card-title>General Comments</v-card-title>
          <v-text-field
            v-model="comments.GEN_COM"
            class="mx-3"
            name="input-7-1"
            multi-line
            readonly
          ></v-text-field>
        </v-card>
        <v-card class="pa-3">
          <v-card-title>Service Comments</v-card-title>
          <v-text-field
            v-model="comments.COM"
            class="mx-3"
            name="input-7-1"
            multi-line
            readonly
          ></v-text-field>
        </v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" outline text @click="commentsDialog = false"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="ct" v-model="ctDialog">
      <v-card class="pa-3">
        <v-card class="pa-3">
          <v-card-title class="headline text-xs-center">{{
            ct.length > 0 ? ct[0].CT_FULL_NM : ''
          }}</v-card-title>

          <v-timeline align-top>
            <v-timeline-item
              v-for="(item, i) in ct"
              :key="i"
              color="grey lighten-3"
              fill-dot
            >
              <template v-slot:icon>
                <span>{{ item.CT_DIRECTION }}</span>
              </template>
              <v-card color="grey lighten-3">
                <v-card-title class="title d-flex">
                  <span>{{ item.CT_REASON_DESC }}</span>
                  <span style='text-align: right;'>{{ moment(item.CT_START_DT_TM).format('ddd MMM DD YYYY H:mm a') }}</span>
                </v-card-title>
                <v-card-text class="white text--primary">
                  <p>{{ item.CT_NOTES }}</p>
                  <strong>Received by: {{ item.EMP }}</strong>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" outline text @click="ctDialog = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <pre>{{ rawData[0] }}</pre> -->
  </v-container>
</template>

<script>
// @ is an alias to /src
//import axios from 'axios'
import uniq from 'lodash/uniq'
import EventBus from '@/eventBus'
import http from '@/http'
import handleError from '@/handleError'
import orderBy from 'lodash/orderBy'
import debounce from 'lodash/debounce'
import { ExportToCsv } from 'export-to-csv'
import moment from 'moment'

// 33192626
export default {
  name: 'Fiber',
  components: {},
  data() {
    return {
      search: '',
      statusSelection: 'ALL',
      taskSelection: 'ALL',
      stageSelection: 'ALL',
      // isMobile: false,
      pagination: {
        sortBy: 'BI_OPEN_DT'
      },
      headers: [
        {
          text: 'Map Locaton',
          align: 'left',
          value: 'BI_SRV_MAP_LOC'
        },
        {
          text: 'Member',
          value: 'Name'
        },
        {
          text: 'Address',
          value: 'BI_ADDR1'
        },
        {
          text: 'City',
          value: 'BI_CITY'
        },
        {
          text: 'Zone',
          value: 'ZONE'
        },
        {
          text: 'Open Date',
          value: 'OPEN_DT'
        },
        {
          text: 'Task',
          value: 'TASK'
        },
        {
          text: 'WorkGRP',
          value: 'WORKGRP'
        },
        {
          text: 'Assigned',
          value: 'ASSIGNED'
        },
        {
          text: 'Actions'
        },
        {
          text: 'Phone Num',
          value: 'PHONE_NUM'
        },
        {
          text: 'Email',
          value: 'EMAIL'
        }
      ],
      commentsDialog: false,
      comments: {},
      ctDialog: false,
      ct: []
    }
  },
  methods: {
    moment,
    debounceSearch: debounce(function(v) { this.search = v }, 600),
    goTo(mapLocation) {
      mapLocation = mapLocation.substring(0, mapLocation.length - 1)
      this.$router.push(`/map/${mapLocation}`)
    },
    getComments(soNum) {
      http
        .get(`/soComments/${soNum}`)
        .then(response => {
          if (response.data.soComments.length > 0) {
            this.comments = response.data.soComments[0]
            this.commentsDialog = true
          } else {
            EventBus.$emit('toast', {
              message: 'No comments available',
              color: 'warning'
            })
          }
        })
        .catch(handleError)
    },
    getCT(custNum) {
      http
        .get(`/ct/${custNum}`)
        .then(response => {
          if (response.data.ct.length > 0) {
            response.data.ct.forEach(i => {
              i.CT_START_DT_TM = new Date(i.CT_START_DT_TM)
            })
            this.ct = orderBy(response.data.ct, 'CT_START_DT_TM', 'desc')
            this.ctDialog = true
          } else {
            EventBus.$emit('toast', {
              message: 'No contact tracking available',
              color: 'warning'
            })
          }
        })
        .catch(handleError)
    },
    changeSort(column) {
      console.log(column)
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },
    exportCsv() {
      const options = {
        filename: 'Fiber Data',
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true
        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
      }

      const csvExporter = new ExportToCsv(options)

      csvExporter.generateCsv(this.stageFilteredRawData)
    }
  },
  computed: {
    statusFilteredRawData() {
      let data = this.rawData
      if (this.statusSelection !== 'ALL') {
        data = data.filter(i => i.STATUS === this.statusSelection)
      }
      return data
    },
    taskFilteredRawData() {
      let data = this.statusFilteredRawData
      // if (this.statusSelection === 'ALL') {
      //   this.taskSelection = 'ALL'
      // }
      if (this.taskSelection !== 'ALL') {
        data = data.filter(i => i.TASK === this.taskSelection)
      }
      return data
    },
    stageFilteredRawData() {
      let data = this.taskFilteredRawData
      if (this.stageSelection !== 'ALL') {
        data = data.filter(i => i.TASK_STAT === this.stageSelection)
      }
      return data
    },
    statusOptions() {
      let options = uniq(this.rawData.map(i => i.STATUS))
      options.splice(0, 0, 'ALL')
      return options
    },
    taskOptions() {
      let options = uniq(this.statusFilteredRawData.map(i => i.TASK))
      options.splice(0, 0, 'ALL')
      return options
    },
    stageOptions() {
      let options = uniq(this.taskFilteredRawData.map(i => i.TASK_STAT))
      options.splice(0, 0, 'ALL')
      return options
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    rawData() {
      return this.$store.state.table.data
    },
    loading() {
      return this.$store.state.table.loading
    }
  }
}
</script>

<style scoped>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
