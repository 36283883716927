import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import http from '@/http'
import 'c3/c3.min.css'
import 'typeface-roboto'
import 'material-design-icons/iconfont/material-icons.css'
import '@/arcgis'
//require('@/assets/main.css')

Vue.config.productionTip = false
Vue.prototype.$http = http

const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

window.navigate = function(lat, long) {
  if (typeof device != 'undefined' && device.platform === 'iOS') {
    window.open(`http://maps.apple.com/?q=${lat},${long}`, '_system')
  } else {
    window.open(`http://maps.google.com/?q=${lat},${long}`, '_system')
  }
}
