<template>
  <v-container align-center justify-space-around>

    <v-container>
      <v-layout wrap>
        <v-flex sm4 class="text-xs-center">
          <v-card class="elevation-5 py-2 mx-5">
            <h3>Active Accounts: {{ $store.state.fiber.chartTotal.ACTIVE }}</h3>
          </v-card>
        </v-flex>
        <v-flex sm4 class="text-xs-center">
          <v-card class="elevation-5 py-2 mx-5">
            <h3>Pending Accounts: {{ $store.state.fiber.chartTotal.PENDING }}</h3>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-card class="elevation-3 pt-4">
      <v-progress-linear :indeterminate="true" v-if="$store.state.fiber.loadingChartTotal"></v-progress-linear>
      <div v-once id="dailyActivity"></div>
    </v-card>

    <v-container>
      <v-layout wrap>
        <v-flex sm4 class="text-xs-center">
          <v-card class="elevation-5 py-2 mx-5">
            <h3>Drops: {{ $store.state.fiber.chartTotal.DROP }}</h3>
          </v-card>
        </v-flex>
        <v-flex sm4 class="text-xs-center">
          <v-card class="elevation-5 py-2 mx-5">
            <h3>Light RDG: {{ $store.state.fiber.chartTotal.LIGHT_RDG }}</h3>
          </v-card>
        </v-flex>
        <v-flex sm4 class="text-xs-center">
          <v-card class="elevation-5 py-2 mx-5">
            <h3>Install: {{ $store.state.fiber.chartTotal.INSTALL }}</h3>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-card class="elevation-3 pt-4">
      <v-progress-linear :indeterminate="true" v-if="$store.state.fiber.loadingChartTotal"></v-progress-linear>
      <div v-once id="pendingTask"></div>
    </v-card>

    <v-container>
      <v-layout wrap>
        <v-flex sm4 class="text-xs-center">
          <v-card class="elevation-5 py-2 mx-5">
            <h3>Completed Drops: {{ $store.state.fiber.chartTotal.COM_DROP }}</h3>
          </v-card>
        </v-flex>
        <v-flex sm4 class="text-xs-center">
          <v-card class="elevation-5 py-2 mx-5">
            <h3>Completed Light RDG: {{ $store.state.fiber.chartTotal.COM_LIGHT_RDG }}</h3>
          </v-card>
        </v-flex>
        <v-flex sm4 class="text-xs-center">
          <v-card class="elevation-5 py-2 mx-5">
            <h3>Completed Install: {{ $store.state.fiber.chartTotal.COM_INSTALL }}</h3>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-card class="elevation-3 pt-4">
      <v-progress-linear :indeterminate="true" v-if="$store.state.fiber.loadingChartTotal"></v-progress-linear>
      <div v-once id="completedTask"></div>
    </v-card>

    <v-container>
      <v-layout wrap>
        <v-flex sm4 class="text-xs-center">
          <v-card class="elevation-5 py-2 mx-5">
            <h3>Office SignUps: {{ $store.state.fiber.chartTotal.Office }}</h3>
          </v-card>
        </v-flex>
        <v-flex sm4 class="text-xs-center">
          <v-card class="elevation-5 py-2 mx-5">
            <h3>SmartHub SignUps: {{ $store.state.fiber.chartTotal.SmartHub }}</h3>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    
    <v-card class="elevation-3 pt-4 my-5">
      <v-progress-linear :indeterminate="true" v-if="$store.state.fiber.loadingChartTotal"></v-progress-linear>
      <div v-once id="acctSignups"></div>
    </v-card>

    <v-card class="elevation-3 pt-4 my-5">
      <v-progress-linear :indeterminate="true" v-if="$store.state.fiber.loadingChartTotal"></v-progress-linear>
      <div v-once id="fiberAccts"></div>
    </v-card>

  </v-container>
</template>

<script>
import get from 'lodash/get'
import EventBus from '@/eventBus'
import * as c3 from 'c3'

export default {
  name: 'Fiber',
  methods: {
    get(o, p, d) {
      return get(o, p, d)
    },
    renderChart() {
      console.log('renderChart()', this.$store.state.fiber.chartData.length)

      //Daily Activity Chart
      c3.generate({
        bindto: '#dailyActivity',
        size: {
          height: 300
        },
        data: {
          x: { S_DT: 'status' },
          json: this.dailyActivityData,
          labels: true,
          keys: {
            x: 'S_DT',
            value: ['ACTIVE', 'PENDING']
          }
        },
        tooltip: {
          grouped: true
        },
        axis: {
          x: {
            type: 'category',
            tick: {
              fit: true
            }
          }
        }
      })

      //Pending Task Chart
      c3.generate({
        bindto: '#pendingTask',
        size: {
          height: 300
        },
        title:{text:'Pending Task Per Day', position: 'top-center'},

        data: {
          x: { S_DT: 'status' },
          json: this.pendingTaskData,
          type: 'bar',
          labels: true,
          keys: {
            x: 'S_DT',
            value: ['INSTALL', 'DROP', 'LIGHT_RDG']
          }
        },
        tooltip: {
          grouped: true
        },
        axis: {
          x: {
            type: 'category',
            tick: {
              fit: true
            }
          }
        }
      })

      //Completed Task Chart
      c3.generate({
        bindto: '#completedTask',
        size: {
          height: 300
        },
        title:{text:'Completed Task Per Day', position: 'top-center'},

        data: {
          x: { S_DT: 'status' },
          json: this.comTask,
          type: 'bar',
          labels: true,
          keys: {
            x: 'S_DT',
            value: ['COM_INSTALL', 'COM_DROP', 'COM_LIGHT_RDG']
          }
        },
        tooltip: {
          grouped: true
        },
        axis: {
          x: {
            type: 'category',
            tick: {
              fit: true
            }
          }
        }
      })

      //Active Accounts Chart
      c3.generate({
        bindto: '#fiberAccts',
        size: {
          height: 300
        },
        title:{text:'Active Fiber Accounts', position: 'top-center'},

        data: {
          x: { S_DT: 'status' },
          json: this.runningTotal,
          labels: true,
          keys: {
            x: 'S_DT',
            value: ['RUNNING_TOTAL']
          }
        },
        tooltip: {
          grouped: true
        },
        axis: {
          x: {
            type: 'category',
            tick: {
              fit: true
            }
          }
        }
      })

      //Account Signups Chart
      c3.generate({
        bindto: '#acctSignups',
        size: {
          height: 300
        },
        title:{text:'Signups Method', position: 'top-center'},

        data: {
          x: { S_DT: 'status' },
          json: this.signUpMethod,
          labels: true,
          type: 'bar',
          groups: [['SmartHub', 'Office']],
          keys: {
            x: 'S_DT',
            value: ['SmartHub', 'Office']
          }
        },
        tooltip: {
          grouped: true
        },
        axis: {
          x: {
            type: 'category',
            tick: {
              fit: true
            }
          }
        }
      })

    },
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(","),
        ...arrData.map(item => Object.values(item).join(","))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    }
  },
  computed: {
    chartData() {
      return this.$store.state.fiber.chartData
    },
    dailyActivityData() {
      let data = []
      this.chartData.forEach(i => {
        if (i.hasOwnProperty('ACTIVE') && i.hasOwnProperty('PENDING') && i.ACTIVE !== null && i.PENDING !== null)
          data.push(i)
      })
      return data
    },
    pendingTaskData() {
      let data = []
      this.chartData.forEach(i => {
        if (i.hasOwnProperty('INSTALL') && i.hasOwnProperty('DROP') && i.hasOwnProperty('LIGHT_RDG') && i.INSTALL !== null && i.DROP !== null && i.LIGHT_RDG !== null)
          data.push(i)
      })
      return data
    },
    comTask() {
      let data = []
      this.chartData.forEach(i => {
        if (i.hasOwnProperty('COM_INSTALL') && i.hasOwnProperty('COM_DROP') && i.hasOwnProperty('COM_LIGHT_RDG') && i.COM_INSTALL !== null && i.COM_DROP !== null && i.COM_LIGHT_RDG !== null)
          data.push(i)
      })
      return data
    },
    signUpMethod() {
      let data = []
      this.chartData.forEach(i => {
        if (i.hasOwnProperty('SmartHub') && i.hasOwnProperty('Office') && i.SmartHub !== null && i.Office !== null)
          data.push(i)
      })
      return data
    },
    runningTotal() {
      let data = []
      this.chartData.forEach(i => {
        if (i.hasOwnProperty('RUNNING_TOTAL') && i.RUNNING_TOTAL !== null)
          data.push(i)
      })
      return data
    }
    // active() {
    //   return this.$store.state.fiber.chartTotal.length > 0 ? this.$store.state.fiber.chartTotal[0].ACTIVE : 0
    // },
    // pending() {
    //   return this.$store.state.fiber.chartTotal.length > 0 ? this.$store.state.fiber.chartTotal[0].PENDING : 0
    // }
  },
  created() {
    EventBus.$on('renderChart', () => this.renderChart())
  },
  beforeRouteEnter(to, from, next) {
    next(() => {
      console.log('enter')
      setTimeout(() => {
        EventBus.$emit('renderChart')
      }, 500)
    })
  }
}
</script>
