// import http from '@/http'
// import handleError from '@/handleError'

export default {
  namespaced: true,
  state: {
    loadingChartData: false,
    loadingChartTotal: false,
    chartData: [],
    chartTotal: {}
      // S_DT: "today",
      // ACTIVE: 2684,
      // PENDING: 755,
      // INSTALL: 68,
      // DROP: 524,
      // LIGHT_RDG: 165,
      // COM_INSTALL: 105,
      // COM_DROP: 108,
      // COM_LIGHT_RDG: 0,
      // SmartHub: 0,
      // Office: 0
  }

  // actions: {
  //   fetchFiberData({ commit }) {
  //     commit('set_loading', true)
  //     http
  //       .get('/acctPerDay')
  //       .then(response => {
  //         commit('save_data', response.data.items)
  //       })
  //       .catch(handleError)
  //       .finally(() => commit('set_loading', false))
  //   },

  //   fetchFiberActiveData({ commit }) {
  //     commit('set_active_loading', true)
  //     http
  //       .get('/activeRunningTotal')
  //       .then(response => {
  //         commit('save_active_data', response.data.items)
  //       })
  //       .catch(handleError)
  //       .finally(() => commit('set_active_loading', false))
  //   },

  //   fetchPendingTaskeData({ commit }) {
  //     commit('set_pending_task_loading', true)
  //     http
  //       .get('/pendingTask')
  //       .then(response => {
  //         commit('save_pending_task_data', response.data.items)
  //       })
  //       .catch(handleError)
  //       .finally(() => commit('set_pending_task_loading', false))
  //   }
}
